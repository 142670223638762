<template>
  <div class="check-out-success">
    <div class="check-out-success__content">
      <h1>{{ $t('spare_card_success.content.title') }}</h1>

      <ProfileDetail />
      <LanguageSelect absolutePosition />

      <div v-if="showTTLock" class="room-number ttlock-preview">
        <div :class="`${ttLockData.pincode ? 'pincode' : null}`">
          {{ ttLockData.pincode ? '*' + ttLockData.pincode + '#' : null }}
        </div>
        <br />
        {{ $t('room_directions.room.title') }}: {{ room.code }}
      </div>
      <div v-else>
        {{ room.code }}
      </div>

    </div>
    <div class="check-out-success__actions">
      <ButtonInput @click="logEvent('onSpareCardIssueSuccessLogoutClicked') & clearReservationStoreAndRedirect()">
        {{ $t('action.button.logout_exit') }}
      </ButtonInput>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import {
  UPDATE_RESERVATION,
  GET_ROOM_BY_ID,
  GET_TTLOCK_DATA,
} from '@/store/reservation';
import { GET_PROPERTY_DETAIL } from '@/store/property';
import LanguageSelect from '@/components/LanguageSelect/index';
import ButtonInput from '@/components/Form/Input/ButtonInput';
import ProfileDetail from '@/components/ProfileDetail';
import { logEvent } from '@/helpers/analytics';
import { TTLOCK as KEY_SYSTEM_TTLOCK } from '@/constants/keySystemType.js';

export default {
  name: 'SpareCardSuccess',
  async mounted() {
    if (!this.room) {
      await this.$router.push('/home');
    }
  },
  components: {
    ButtonInput,
    LanguageSelect,
    ProfileDetail,
  },
  data() {
    return {
      roomId: this.$route.params.id,
    };
  },
  computed: {
    ...mapGetters({
      getRoomById: GET_ROOM_BY_ID,
      property: GET_PROPERTY_DETAIL,
      ttLockData: GET_TTLOCK_DATA,
    }),
    room() {
      return this.getRoomById(this.roomId);
    },
    keyService() {
      return _.get(this.property, 'key_service');
    },
    showTTLock() {
      return this.keyService === KEY_SYSTEM_TTLOCK;
    },
  },
  methods: {
    logEvent,
    clearReservationStoreAndRedirect() {
      return this.$store.dispatch(UPDATE_RESERVATION, {})
        .then(() => {
          this.$router.push({name: 'home'});
        })
        .catch(() => {
          this.$router.push({name: 'home'});
        });
    },
  },
};
</script>

<style lang="scss">
  .check-out-success {
    text-align: center;
    width: calc(100% - 4rem);
    max-width: 50rem;

    h1 {
      font-size: 3rem;
    }

    &__content {
      margin-bottom: 2rem;
    }

    &__actions {
      display: flex;
      justify-content: center;

      .button {
        flex: 0 50%;
        margin: 0 1rem;
      }
    }
  }

  .room-number {
    font-size: 4rem;
    font-weight: bold;
    line-height: 4rem;
    margin-bottom: 4rem;

    &.ttlock-preview {
      font-size: 2rem;
      line-height: 2rem;
    }

    .pincode {
      font-size: 4rem;
      line-height: 5.5rem;
    }
  }
</style>
